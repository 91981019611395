<template>
  <v-card :loading="loading" class="mx-auto overflow-auto" :style="{width:'calc(100%)', height:'calc(100%)'}">
    <v-carousel id="carimg" :show-arrows="false" hide-delimiter-background height="100%" @change="handleChange" >
      <v-carousel-item v-for="(layout, i) in items" :key="i" v-touch="{ left: () => swipeLeft(), right: () => swipeRight()}">
        <v-img :src="layout.src" :style="{width:'calc(100%)',position: 'absolute',  top: '0px', bottom: '0px'}"></v-img>
      </v-carousel-item>
    </v-carousel>
    <!--
      :disable3d="true" :perspective="0" :space="188" :inverse-scaling="0"                 fwidth:'168px',
                fheight:'168px',
      when only 2 imgs:need to set .carousel-3d-slider css and bias prop,may need to set css by document.getElement.setCss
    -->
  <carousel-3d v-if="slides.length>2" ref="mycarousel" :count="slides.length" :clickable="true" :width="currentlayoutPic[userAgent]?.fwidth" :height="currentlayoutPic[userAgent]?.fheight" :bias="'middle'" :border="0"  :controls-visible="false" :on-main-slide-click="redirectTo" :style="{height:'cal(100%) !important',position: 'absolute',bottom: currentlayoutPic[userAgent]?.bottom,top:currentlayoutPic[userAgent]?.top,left: 0,right: 0,margin: 'auto'}">
    <slide v-for="(slide, i) in slides" :index="i" :key="i" :style="{width: slide[userAgent]?.width,height:'cal(100%) !important'}">
      <img  :src="slide.src" :style="{width: slide[userAgent]?.width,height:slide[userAgent]?.height}"/>
    </slide>
  </carousel-3d>

  <div v-else-if="slides.length==2" id="twoImgs" :style="{height:currentlayoutPic[userAgent]?.fheight,width:currentlayoutPic[userAgent]?.fwidth,position: 'absolute',bottom: currentlayoutPic[userAgent]?.bottom,top:currentlayoutPic[userAgent]?.top,left: 'calc(10%)',right: 'calc(10%)',margin: 'auto'}">
    <img v-for="(slide, i) in slides" :key="i" :src="slide.src" :style="{width: slide[userAgent]?.width,height:slide[userAgent]?.height,float:slide[userAgent]?.float}" @click="redirectTo({index:i})"/>
  </div>

  <div v-else id="oneImgs" :style="{height:currentlayoutPic[userAgent]?.fheight,width:currentlayoutPic[userAgent]?.fwidth,position: 'absolute',top:currentlayoutPic[userAgent]?.top,bottom: currentlayoutPic[userAgent]?.bottom,left: 'calc(25%)',right: 'calc(25%)',margin: 'auto'}">
    <v-img v-for="(slide, i) in slides" :key="i" :src="slide.src" :style="{width: slide[userAgent]?.width,height:slide[userAgent]?.height}" @click="redirectTo({index:i})">
    </v-img>
  </div>
  <div>
        <span style="
      position: absolute;
      bottom: 0px;
      font-size: 12px;
      left: 3px;
  ">沪ICP备10002431号
      </span>
      <span style="
          bottom: 0px;
          position: absolute;
          right: 3px;
          font-size: 12px;
      ">沪公网安备31010502000766号
      </span>
    </div>
  </v-card>
</template>

<script>
import api from "../api/api";
import { mapMutations } from 'vuex';

export default {
  name: "SystemsDetail",
  data: () => ({
    preload: '2',
    loading: false,
    currentPicIndex:0,
    subSysname:'',
    comfortActuators: [{
    }],
    currentlayoutPic:{},
    items:[],
    defaultWidthHeight: 168,
    userAgent:'',
    slides: [],
  }),
  mounted() {
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { //判断iPhone|iPad|iPod|iOS
        this.userAgent = 'iPhone';
    } else if (/(Android)/i.test(navigator.userAgent)) {  //判断Android
        this.userAgent = 'Android';
    } else { //pc
        this.userAgent = 'Android';
    }
    this.subSysname = this.$route.query.subSysname || "wheel";
    const gifData = api.hanbook.getGifData(this.subSysname);
    if(gifData && gifData.length>0){
      this.items = gifData;
      this.currentlayoutPic = this.items[this.currentPicIndex];
      this.slides = this.currentlayoutPic.models;
      //this.$refs.mycarousel.goSlide(0);
    }
    
  },
  computed: {
    navLink() {
      return [
      ];
    }
  },
  methods: {
    swipeLeft(){
      this.previousImage();
    },
    swipeRight(){
      this.nextImage();
    },
    handleChange(modelValue){
      this.currentPicIndex = modelValue;
      this.goToImage(modelValue);
    },
    goToImage(modelValue){
      this.currentPicIndex = modelValue;
      this.currentlayoutPic = this.items[this.currentPicIndex];
      this.slides = this.currentlayoutPic.models;
    },
    nextImage(){
      this.currentPicIndex = (this.currentPicIndex + 1) % this.items.length;
      this.goToImage(this.currentPicIndex);
    },
    previousImage(){
      this.currentPicIndex = (this.currentPicIndex - 1 + this.items.length) % this.items.length;
      this.goToImage(this.currentPicIndex);
    },
    hoverColor(event) {
      event.target.style.backgroundColor = "#ff41005e";
    },
    redirectTo(current) {
      let slide = this.slides[current.index];
      let redirectRoute = '';
      if (['forward', 'backward', 'blow','compress','cooldown','pump'].includes(this.subSysname)) {
        redirectRoute = '/Twomodel';
      } else {
        redirectRoute = '/Threemodel';
      }
      this.previousPicIndex = current.index;
      this.$router.push({
        path: redirectRoute,
        query: {
          name: slide.modelName,
          subSysname:this.subSysname,
        }, 
      });
    }
  }
};
</script>

<style lang="sass">
.home
  height: calc(100% - 0px)
  
#carimg
  height: calc(var(--vh, 1vh) * 100)
  .v-image__image
    background-position: 0px 0px !important
    background-size: calc(100%) calc(100%)
    max-width: 100% !important

h4
  font-size: 20px
  line-height: 1.25
  font-family: BoschSans-Light,Helvetica,Arial,sans-serif
  color: #005691
  font-style: normal
p
  font-size: 16px !important
  line-height: 1.5 !important
  font-family: BoschSans-Light,Helvetica,Arial,sans-serif !important
.carousel-3d-slide
  background-color: TRANSPARENT !important
  .v-image
    .v-image__image
      background-position: center center !important
      max-width: 100% !important
div.v-carousel__controls
  top: 51.5% !important
i.v-icon
  color: rgba(0, 0, 0, 0.3) !important
  opacity: 0.3 !important
.mdi:before
  font-size: 12px !important
button.v-carousel__controls__item
  margin-left: 0px !important
  margin-right: 0px !important
.carousel-3d-container
  width:100% !important
#twoImgs
  background-color: TRANSPARENT !important
  .v-image
    .v-image__image
      background-position: center center !important
      background-size:100% 100%
#oneImgs
  background-color: TRANSPARENT !important
  .v-image
    .v-image__image
      background-position: center center !important
      background-size:100% 100%
</style>